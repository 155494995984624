import * as authActionsConstants from '../constants/actions/auth';

const initialState = {
  currentUser: {},
  currentUserStats: {},
  currentUserStatsLoading: false,
  signedInUser: {},
  signUpLoading: false,
  signInLoading: false,
  googleConnectionLoading: false,
  magicLink: {},
  phoneConfirmationSent: false,
  phoneConfirmationData: undefined,
  permissions: {
    contacts: false,
    notification: false,
    location: false,
  },
  adminAppUrl: '',
  isAnyOrgAdmin: false,
  inviteData: {},
  invitedByUserId: null,
  userInviteContact: true,
  loginFormError: false,
  viewSignInForm: true,
  userExist: {},
  startLinkedinExport: null,
  userSources: [],
  myAccountCurrentTab: 'profile',
  currentSelectedOrg: {
    label: 'Boon',
    value: 'Boon',
    id: null,
    logo: 'https://boon-co-app.s3.amazonaws.com/static/media/boon-image.png',
    isUsHeadquarter: true,
  },
  showSearchBlock: false,
  slackUserId: null,
  isRedirectUserToAnotherUrl: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionsConstants.SIGN_UP:
      return {
        ...state,
        currentUser: {},
        signUpLoading: true,
      };
    case authActionsConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload.user,
          user_connections_count: action.payload.user_connections_count,
          user_referrals_sent_count: action.payload.user_referrals_sent_count,
          user_referrals_received_count: action.payload.user_referrals_received_count,
          user_companies_count: action.payload.user_companies_count,
        },
        currentUserStats: action.payload.stats,
        signUpLoading: false,
        adminAppUrl: action.payload.admin_app_url,
        magicLink: {},
      };

    case authActionsConstants.SIGN_UP_ERROR:
      return {
        ...state,
        signUpLoading: false,
        magicLink: { response_code: 0 },
      };

    case authActionsConstants.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload.user },
      };
    case authActionsConstants.GET_SIGNED_IN_USER:
      return {
        ...state,
        signedInUser: action.payload,
      };
    case authActionsConstants.SIGN_IN:
      return {
        ...state,
        currentUser: {},
        signInLoading: true,
      };
    case authActionsConstants.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload.user,
          user_connections_count: action.payload.user_connections_count,
          user_referrals_sent_count: action.payload.user_referrals_sent_count,
          user_referrals_received_count: action.payload.user_referrals_received_count,
          user_companies_count: action.payload.user_companies_count,
        },
        currentUserStats: action.payload.stats,
        signInLoading: false,
        magicLink: {},
        adminAppUrl: action.payload.admin_app_url,
        isAnyOrgAdmin: action.payload.any_org_admin,
      };
    case authActionsConstants.SIGN_IN_ERROR:
      return {
        ...state,
        signInLoading: false,
        loginFormError: true,
        magicLink: { response_code: 0 },
      };
    case authActionsConstants.LOGIN_FORM_ERROR:
      return {
        ...state,
        loginFormError: action.payload,
      };
    case authActionsConstants.GET_CURRENT_USER_STATS:
      return {
        ...state,
        currentUserStatsLoading: true,
      };
    case authActionsConstants.GET_CURRENT_USER_STATS_SUCCESS:
      return {
        ...state,
        currentUserStats: action.payload,
        currentUserStatsLoading: false,
      };
    case authActionsConstants.GET_CURRENT_USER_STATS_ERROR:
      return {
        ...state,
        currentUserStatsLoading: false,
      };
    case authActionsConstants.SUBMIT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, permissions_passed: true },
      };
    case authActionsConstants.EDIT_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          user_connections_count: state.currentUser.user_connections_count,
          user_referrals_sent_count: state.currentUser.user_referrals_sent_count,
        },
      };
    case authActionsConstants.SUBSCRIBE_GOOGLE:
      return {
        ...state,
        currentUser: { ...state.currentUser, sync_google_people_contacts: true },
      };

    case authActionsConstants.SUBSCRIBE_LINKEDIN:
      return {
        ...state,
        currentUser: { ...state.currentUser, linkedin_authenticated: true },
      };
    case authActionsConstants.SUBSCRIBE_OUTLOOK:
      return {
        ...state,
        currentUser: { ...state.currentUser, outlook_authenticated: true },
      };

    case authActionsConstants.SUBSCRIBE_FACEBOOK:
      return {
        ...state,
        currentUser: { ...state.currentUser, facebook_authenticated: true },
      };
    case authActionsConstants.SUBSCRIBE_TWITTER:
      return {
        ...state,
        currentUser: { ...state.currentUser, twitter_authenticated: true },
      };
    case authActionsConstants.UNSUBSCRIBE_LINKEDIN:
      return {
        ...state,
        currentUser: { ...state.currentUser, linkedin_authenticated: false },
      };
    case authActionsConstants.UNSUBSCRIBE_OUTLOOK:
      return {
        ...state,
        currentUser: { ...state.currentUser, outlook_authenticated: false },
      };
    case authActionsConstants.UNSUBSCRIBE_FACEBOOK:
      return {
        ...state,
        currentUser: { ...state.currentUser, facebook_authenticated: false },
      };
    case authActionsConstants.UNSUBSCRIBE_TWITTER:
      return {
        ...state,
        currentUser: { ...state.currentUser, twitter_authenticated: false },
      };
    case authActionsConstants.UNSUBSCRIBE_GOOGLE:
      return {
        ...state,
        googleConnectionLoading: true,
      };
    case authActionsConstants.UNSUBSCRIBE_GOOGLE_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, sync_google_people_contacts: false },
        googleConnectionLoading: false,
      };
    case authActionsConstants.CHANGE_PERMISSION_STATUS:
      return {
        ...state,
        permissions: { ...state.permissions, ...action.payload },
      };
    case authActionsConstants.LOGOUT:
      return {
        ...state,
        currentUser: {},
        currentUserStats: {},
      };
    case authActionsConstants.GET_MAGIC_LINK:
      return {
        ...state,
        magicLink: { resp_code: undefined },
      };
    case authActionsConstants.SET_MAGIC_LINK_DATA:
      return {
        ...state,
        magicLink: { ...action.payload },
      };
    case authActionsConstants.CLEAR_MAGIC_LINK_DATA:
      return {
        ...state,
        magicLink: {},
      };
    case authActionsConstants.GET_NUMBER_CONFIRMATION:
      return {
        ...state,
        phoneConfirmationData: undefined,
        phoneConfirmationSent: false,
      };
    case authReducer.CONFIRM_NUMBER_SUCCESS:
      return {
        ...state,
        phoneConfirmationData: undefined,
        phoneConfirmationSent: false,
      };
    case authActionsConstants.GET_NUMBER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        phoneConfirmationData: action.payload,
        phoneConfirmationSent: true,
      };
    case authActionsConstants.RESET_CONFIRM_NUMBER:
      return {
        ...state,
        phoneConfirmationData: undefined,
        phoneConfirmationSent: false,
      };
    case authActionsConstants.GET_USER_INVITE_DATA:
      return {
        ...state,
        inviteData: {},
      };
    case authActionsConstants.GET_USER_INVITE_DATA_SUCCESS:
      return {
        ...state,
        inviteData: action.payload,
      };
    case authActionsConstants.GET_USER_INVITE_DATA_ERROR:
      return {
        ...state,
        inviteData: {},
      };
    case authActionsConstants.SIGN_UP_CURRENT_USER_ID:
      return {
        ...state,
        invitedByUserId: action.payload,
      };
    case authActionsConstants.USER_INVITE_CONTACT:
      return {
        ...state,
        userInviteContact: action.payload.invite,
      };
    case authActionsConstants.SET_ANY_ORG_ADMIN:
      return {
        ...state,
        isAnyOrgAdmin: action.payload,
      };
    case authActionsConstants.SET_VIEW_SIGN_IN_FORM:
      return {
        ...state,
        viewSignInForm: action.payload,
      };
    case authActionsConstants.GET_USER_BY_EMAIL:
      return {
        ...state,
        userExist: action.payload,
      };
    case authActionsConstants.SET_START_DOWNLOAD_TIME:
      return {
        ...state,
        startLinkedinExport: action.payload,
      };
    case authActionsConstants.SET_USER_SOURCES:
      return {
        ...state,
        userSources: action.payload,
      };
    case authActionsConstants.SET_ACCOUNT_CURRENT_TAB:
      return {
        ...state,
        myAccountCurrentTab: action.payload,
      };
    case authActionsConstants.SHOW_SEARCH_BLOCK:
      return {
        ...state,
        showSearchBlock: action.payload,
      };
    case authActionsConstants.SET_CURRENT_SELECTED_ORG:
      return {
        ...state,
        currentSelectedOrg: action.payload,
      };
    case authActionsConstants.UPDATE_USER_SLACK_ID_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, slack_user_id: action.payload },
      };
    case authActionsConstants.GET_SLACK_ID_SUCCESS: {
      return {
        ...state,
        slackUserId: action.payload,
      };
    }
    case authActionsConstants.SET_REDIRECT_URL_LOADER_TRUE: {
      return {
        ...state,
        isRedirectUserToAnotherUrl: true,
      };
    }
    case authActionsConstants.SET_REDIRECT_URL_LOADER_FALSE: {
      return {
        ...state,
        isRedirectUserToAnotherUrl: false,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
