import baseService from './baseService.js';
import factory from '../store';
import { AsyncStorage } from 'react-native';
const { store, persistor } = factory();

const getOrgId = async () => {
  const data = await AsyncStorage.getItem('persist:root');
  const object = JSON.parse(data).authReducer;
  return JSON.parse(object).currentSelectedOrg.id;
};

export function getAll(params, organization) {
  let state = store.getState();
  let checkUser = Object.keys(state.authReducer.currentUser);
  return baseService.get(`/global_index/jobs/`, {
    params: {
      ...params,
      type: params.type || 'all',
      page: params.page || 1,
      per_page: params.per_page || 50,
      order_by_user_membership: true,
      organization_id: checkUser.length > 0 ? state.authReducer.currentSelectedOrg.id : organization && organization.id,
    },
  });
}

const getJobsDepartment = (organization_id, params) => {
  let state = store.getState();
  return baseService.get(`organizations/${organization_id}/browse_jobs`, {
    params: {
      ...params,
      type: params.type || 'all',
      page: params.page || 1,
      per_page: params.per_page || 50,
      order_by_user_membership: true,
      is_us_headquarter: state.authReducer.currentSelectedOrg.isUsHeadquarter
    },
  });
};

export async function getJobs(params, organization_id) {
  if (!organization_id) {
    organization_id = await getOrgId();
    return getJobsDepartment(organization_id, params);
  } else {
    return getJobsDepartment(organization_id, params);
  }
}

export function getAllBySort(sortBy, filters, organization_id) {
  return baseService.get(`/global_index/jobs/`, {
    params: {
      type,
      sort_by: sortBy,
      job_filters: filters,
      organization_id,
    },
  });
}

export function getDetails(type, id, organizationId, fallbackId = null) {
  const url = type === 'job' ? `/organizations/${organizationId}/jobs/${id}` : `/departments/${id}`;
  return baseService.get(url, {
    params: {
      fallbackId: fallbackId,
      organization_id: organizationId,
      is_mobile: true,
    },
  });
}

export function getCandidateData(params) {
  return baseService.get('/refer_requests/candidate_applied_or_declined', {
    params: params,
  });
}

export function getSocialShareLink(openingId) {
    return baseService.post('/member/integration_accounts/share_opening', {
    job_id: openingId,
  });
}
