import React, { useState, useEffect } from "react";
import {
  Image,
  View,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  TextInput,
  Linking
} from "react-native";
import { Field, reduxForm } from "redux-form";
import Actions from '../../RouteActions';
import axios from "axios";
import { connect } from "react-redux";
import { apiSeverUrl } from "../../services/baseService";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Text from "../../components/Text/Text";
import unionIcon from "../../assets/svg/union-icon.svg";
import styles from "./styles";

const SignInWithSSO = (props) => {
  const [loadingAzure, setLoadingAzure] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axios.get(`${apiSeverUrl}/azure_organizations`);
        if (response.data && Array.isArray(response.data.organizations)) {
          setOrganizations(response.data.organizations);
          setFilteredOrganizations(response.data.organizations);
        } else {
          console.error("Invalid response format for organizations");
          setErrorMessage("Failed to load organizations.");
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
        setErrorMessage("Error fetching organizations. Please try again.");
      } finally {
        setLoadingOrganizations(false);
      }
    };
    fetchOrganizations();
  }, []);

  const handleOrganizationSelect = (organization) => {
    setSelectedOrg(organization);
    setErrorMessage("");

    if (organization.name.toLowerCase() === "marketsource" || organization.name.toLowerCase() === "marketsource1") {
      Actions.signInSSO();
    } else {
      handleAzureLogin(organization);
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = organizations.filter((org) =>
      org.name.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredOrganizations(filtered);
  };

  const handleBack = (e) => {
    e.preventDefault();
    history.back();
  };

  const handleAzureLogin = async (selectedOrg) => {
    if (!selectedOrg) return;
    setLoadingAzure(true);
    setErrorMessage("");
    try {
      const response = await axios.post(`${apiSeverUrl}/auth/saml_azure`, {
        organization_id: selectedOrg.id,
      });
      if (response.data.url) {
        Linking.openURL(response.data.url);
      } else {
        console.error("SAML URL not received");
        setErrorMessage("Failed to retrieve SAML login URL.");
      }
    } catch (error) {
      console.error("Error fetching SAML login URL:", error);
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404 || status === 422) {
          setErrorMessage( data && data.message || "Organization Integration not found.");
        } else {
          setErrorMessage("Unexpected error occurred. Please try again.");
        }
      } else {
        setErrorMessage("Network error. Please check your connection.");
      }
    } finally {
      setLoadingAzure(false);
    }
  };

  return (
    <AuthLayout>
      <View style={styles.formWrapper}>
        <View style={styles.header}>
          <View style={styles.iconWrapper}>
            <Image source={unionIcon} resizeMode="contain" style={styles.iconImg} />
          </View>
          <Text style={styles.headerText}>Sign in with SSO</Text>
          <Text style={styles.subHeaderText}>
            Please select your organization to proceed with SSO :
          </Text>
        </View>

        <View style={[styles.formFieldWrapper, { alignItems: "center" }]}>
          {/* Display Error Message Above the List */}
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}

          {/* Search Input Field */}
          <TextInput
            style={styles.searchInput}
            placeholder="Search your organization"
            placeholderTextColor="#999"
            value={searchText}
            onChangeText={handleSearch}
          />

          {loadingOrganizations ? (
            <ActivityIndicator size="small" color="#000" />
          ) : (
            <View style={styles.organizationListContainer}>
              {filteredOrganizations.length === 0 ? (
                <Text style={styles.noOrgFoundText}>No organizations found</Text>
              ) : (
                <FlatList
                  data={filteredOrganizations}
                  keyExtractor={(item) => item.id.toString()}
                  renderItem={({ item }) => (
                    <TouchableOpacity
                      style={[
                        styles.organizationItem,
                        selectedOrg && selectedOrg.id === item.id ? styles.selectedOrganization : null,
                      ]}
                      onPress={() => handleOrganizationSelect(item)}
                    >
                      <Image
                        source={{ uri: item.logo_image_thumb }}
                        style={styles.orgLogo}
                        resizeMode="contain"
                      />
                      <Text style={styles.orgName}>{item.name}</Text>
                    </TouchableOpacity>
                  )}
                />
              )}
            </View>
          )}
        </View>

        <View style={[styles.formFieldWrapper, { marginTop: 20 }]}>
          <PrimaryButton
            onPress={handleBack}
            style={{ height: "auto", width: 80, margin: "auto", backgroundColor: "none" }}
            textStyle={[styles.disabledButtonText, { textDecorationLine: "underline" }]}
          >
            Back
          </PrimaryButton>
        </View>
      </View>
    </AuthLayout>
  );
};

export default connect()(reduxForm({ form: "SignInWithSSOForm" })(SignInWithSSO));
