import React, { useEffect, useMemo, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './styles';
import BoonTextField from '../Basic/BoonTextField';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import axios from 'axios';

const LocationSelect = ({
  locationPresent = false,
  value,
  onChange,
  width = '100%',
  placeholder = 'Location',
  disableAutoFetch,
  noBorder,
  required,
  showRadius,
  iconPosition = 'end',
  usHeadquarter,
}) => {
  const searchOptions = useMemo(
    () => ({
      types: ['(regions)'],
    }),
    []
  );
  const [address, setAddress] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const unit = usHeadquarter ? 'miles' : 'km';
  const [showInnerDropdown, setShowInnerDropdown] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState({ value: 100, label: `Within 100+ ${unit}` });
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const options = [
    { value: 0, label: 'Exact Location' },
    { value: 5, label: `Within 5 ${unit}` },
    { value: 10, label: `Within 10 ${unit}` },
    { value: 25, label: `Within 25 ${unit}` },
    { value: 50, label: `Within 50 ${unit}` },
    { value: 100, label: `Within 100+ ${unit}` },
  ];

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setShowSuggestions(true);

    if (!newAddress) {
      onChange(null);
    }
  };

  const handleSelect = (selectedAddress) => {
    if (!selectedAddress) {
      return onChange(null);
    }

    const location = {};
    geocodeByAddress(selectedAddress)
      .then((results) => {
        location.place_id = results[0].place_id;
        location.full_address = selectedAddress;
        results[0].address_components.forEach((comp) => {
          if (comp.types.includes('postal_code')) {
            location.zip_code = comp.short_name;
          } else if (comp.types.includes('country')) {
            location.country_name = comp.long_name;
            location.country_iso_code = comp.short_name;
          } else if (comp.types.includes('administrative_area_level_1')) {
            location.state = comp.short_name;
          } else if (comp.types.includes('locality')) {
            location.city = comp.long_name;
          } else if (!location.city && comp.types.includes('administrative_area_level_2')) {
            location.city = comp.long_name;
          }
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        location.lat = latLng.lat;
        location.lon = latLng.lng;
        if (showRadius) {
          location.aroundRadius = selectedRadius.value;
        }
        onChange(location);
        setAddress(selectedAddress);
        setShowSuggestions(false);
        setShowInnerDropdown(false);
      })
      .catch((error) => console.error('Error', error));
  };

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          getLocationFromIP();
        }
      );
    } else {
      getLocationFromIP();
    }
  };

  const getLocationFromIP = async () => {
    const url = 'http://ip-api.com/json';
    try {
      const response = await axios.get(url);
      getAddressFromCoordinates(response.data.lat, response.data.lon);
    } catch (error) { }
  };

  const getAddressFromCoordinates = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
        )
        .then(async (responseJson) => {
          if (responseJson.data.status === 'OK') {
            let locationResults = responseJson.data.results.filter(
              (ac) => ~ac.types.indexOf('administrative_area_level_2')
            );
            if (locationResults.length === 0) {
              locationResults = responseJson.data.results.filter((ac) => ~ac.types.indexOf('locality'));
            }
            if (locationResults.length > 0) {
              handleSelect(locationResults[0].formatted_address);
            }
          } else {
            reject('location not found');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const handleDropDown = (e) => {
    setShowInnerDropdown((prev) => !prev);
  };

  const handleInputClick = (option) => {
    setSelectedRadius(option);
    setShowSuggestions(true);
    setShowInnerDropdown(false);
  };

  useEffect(() => {
    if (!locationPresent && !disableAutoFetch) {
      fetchLocation();
    }
  }, [locationPresent]);

  useEffect(() => {
    if (value && value.full_address && !address) {
      setAddress(value.full_address);
    }
  }, [value]);

  return (
    <View style={{ width: width, zIndex: 3 }}>
      <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} searchOptions={searchOptions}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <View>
            <BoonTextField
              id="outlined-basic"
              variant={noBorder ? 'standard' : 'outlined'}
              type="text"
              size="small"
              name="location"
              required={required}
              placeholder={placeholder}
              {...getInputProps({
                inputProps: {
                  className: 'location-input',
                },
                InputProps: {
                  disableUnderline: noBorder ? true : false,
                  endAdornment: iconPosition === 'end' && (
                    <InputAdornment position="end">
                      <LocationOnOutlinedIcon style={{ color: '#BFBFBF' }} />
                    </InputAdornment>
                  ),
                  startAdornment: iconPosition === 'start' && (
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon style={{ color: '#BFBFBF' }} />
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: 14,
                    backgroundColor: '#fff',
                    height: 40,
                    borderRadius: 4,
                    paddingLeft: 5,
                    paddingRight: 5,
                  },
                },
              })}
            />

            {showSuggestions && (
              <View
                style={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: '#fff',
                  marginTop: 45,
                  zIndex: 1,
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {showRadius && suggestions.length > 0 && (
                  <View
                    style={styles.radiusDropdownMainContainer}
                  >
                    <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Radius</Text>

                    <TouchableOpacity
                      onPress={handleDropDown}
                      style={styles.dropDownButton}
                    >
                      <View style={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', }}>
                        <Text>{selectedRadius.label}</Text>
                        <ArrowDropDownIcon />
                      </View>
                    </TouchableOpacity>

                    {showInnerDropdown && (
                      <View
                        style={styles.innerDropdownItem}
                      >
                        {options.map((option, index) => (
                          <TouchableOpacity
                            onPress={() => handleInputClick(option)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            style={{
                              padding: 10,
                              borderBottomWidth: 1,
                              borderBottomColor: '#eee',
                              backgroundColor: hoveredIndex === index ? 'rgb(222, 235, 255)' : '#fff',
                              zIndex: 1000,
                            }}
                          >
                            <Text>{option.label}</Text>
                          </TouchableOpacity>
                        ))}
                      </View>
                    )}
                  </View>
                )}

                {suggestions.map((suggestion) => {
                  const style = suggestion.active ? styles.suggestionItemActive : styles.suggestionItem;

                  return (<TouchableOpacity
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                    style={style}
                  >
                    <Text>{suggestion.description}</Text>
                  </TouchableOpacity>);
                }

                )
                }
              </View>
            )}
          </View>
        )}
      </PlacesAutocomplete>
    </View>
  );
};

export default LocationSelect;
