import React, { Component } from 'react';
import { Platform } from 'react-native';
import Routing, { Router } from './wrappers/Router/Router';
import history from './RouteHistory';
import { urls } from './urls.js';
import Actions from './RouteActions';

import { DeepLinking } from 'react-router-native';
import qs from 'qs';
import { parse } from 'search-params';

//Screens
import ApplyJob from './screens/ApplyJob/ApplyJob';
import SignInWithSSO from './screens/SignInWithSSO/SignInWithSSO.js';
import Browse from './screens/Browse/Browse';
import ChangePassword from './screens/ChangePassword/ChangePassword';
import CompleteProfile from './screens/CompleteProfile/CompleteProfile';
import ContactProfile from './screens/ContactProfile/ContactProfile';
import EditProfile from './screens/EditProfile/EditProfile';
import FollowCompanies from './screens/FollowCompanies/FollowCompanies';
import GrantPermissions from './screens/GrantPermissions/GrantPermissions';
import Home from './screens/Home/Home';
import InitialScreen from './screens/InitialScreen/InitialScreen';
import Invitations from './screens/Invitations/Invitations';
import MagicLink from './screens/MagicLink/MagicLink';
import MyBoon from './screens/MyBoon/MyBoon';
import Notifications from './screens/Notifications/Notifications';
import OpportunityDetails from './screens/OpportunityDetails/OpportunityDetails';
import Recommendations from './screens/Recommendations/Recommendations';
import Refer from './screens/Refer/Refer';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import PublicReferralForm from './screens/PublicReferralForm/PublicReferralForm';
import SetPassword from './screens/SetPassword/SetPassword';
import ForgetPassword from './screens/ForgetPassword/ForgetPassword';
import PostLogin from './screens/PostSignin/PostSignin';
import Settings from './screens/Settings/Settings';
import SignInEmail from './screens/SignInEmail/SignInEmail';
import SignInSSO from './screens/SignInSSO/SignInSSO';
import SignIn from './screens/SingIn/SignIn';
import SignInPassword from './screens/SignInPassword/SignInPassword';
import SignUp from './screens/SignUp/SignUp';
import SignUpPhone from './screens/SignUpPhone/SignUpPhone';
import SignUpConfirmCode from './screens/SignUpConfirmCode/SignUpConfirmCode';
import SuccessScreen from './screens/SuccessScreen/SuccessScreen';
import Tracker from './screens/Tracker/Tracker';
import TrackerSearch from './screens/TrackerSearch/TrackerSearch';
import Web from './screens/Web/Web';
import YourNetworkConnect from './screens/YourNetworkConnect/YourNetworkConnect';
import YourNetworkInitial from './screens/YourNetworkInitial/YourNetworkInitial';
import YourNetworkInvite from './screens/YourNetworkInvite/YourNetworkInvite';
import Invite from './screens/Invite/Invite';
import ThankyouForYourInterest from './screens/ThankyouForYourInterest/ThankyouForYourInterest';
import SignUpLocation from './screens/SignUpLocation/SignUpLocation';
import AccessRequest from './screens/AccessRequest/AccessRequest';
import LinkedinCallback from './screens/LinkedinSsoLogin/LinkedinCallback';

import { redirectToCurrentStep } from './services/stepsService';
import PublicJobApplication from './screens/PublicJobApplication/PublicJobApplication';
import DashboardPublicJobApplication from './screens/DashboardPublicJobApplication/DashboardPublicJobApplication';
import JobPost from './screens/JobPost/JobPost';
import PublicReferral from './screens/PublicReferral/PublicReferral';
import ReferredJobPost from './screens/ReferredJobPost/ReferredJobPost';
import PublicJobDecline from './screens/PublicJobDecline/PublicJobDecline';
import EmployerProfileOverview from './screens/EmployerProfile/EmployerProfileOverview/EmployerProfileOverview';
import LoginRequest from './screens/LoginRequest/LoginRequest';
import OpeningDetail from './screens/Browse/components/Browse/OpeningDetail';
import Unsubscribe from './screens/Unsubscribe/Unsubscribe';
import PageTracker from './PageTracker';

const { Route, Switch, Redirect } = Routing;

class Routes extends Component {


  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("azure_error");
    const step = searchParams.get("step");

    this.state = {
        step: step,
        errorMessage: error 
            ? "This account doesn’t have access to Boon. Please contact your administrator." 
            : null,
    };
}

  
  render() {
    const { tokenPresent } = this.props;
    const step = this.state.step || this.props.step;
    if (step && step != 'home' && history.location.pathname != '/confirm-email-tokens') {
      redirectToCurrentStep(step);
    }
    return (
      <Router history={history}>
        <PageTracker>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => {
                if (step === 'home') {
                  Actions.home();
                } else {
                  Actions.signInEmail();
                }
                return null;
              }}
            />
            <Route exact path={urls.applyJob} component={(props) => <ApplyJob {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.confirmPhone}
              component={(props) => <SignUpPhone {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.unsubscribeMessage}
              component={(props) => <Unsubscribe {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.confirmCode}
              component={(props) => <SignUpConfirmCode {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.successScreen}
              component={(props) => <SuccessScreen {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.signIn}
              component={(props) => <SignInEmail {...props} {...props.location.state} signedIn={true} errorMessage={this.state.errorMessage} />}
            />
            <Route
              exact
              path={urls.signInSSO}
              component={(props) => <SignInSSO {...props} {...props.location.state} signedIn={true} />}
            />
            <Route
              exact
              path={urls.signInWithSSO}
              component={(props) => {
                if (tokenPresent) {
                  Actions.home();
                  return null;
                }
                return <SignInWithSSO {...props} {...props.location.state} signedIn={true} />;
              }}
            />
            <Route
              exact
              path={urls.postLogin}
              component={(props) => <PostLogin {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.contactProfile}
              component={(props) => <ContactProfile {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.signInPassword}
              component={(props) => <SignInPassword {...props} {...props.location.state} signedIn={true} />}
            />
            <Route exact path={urls.signUp} component={(props) => <SignUp {...props} {...props.location.state} />} />
            <Route exact path={urls.signUpOrgCreation} component={(props) => <SignUp {...props} {...props.location.state} organization='organization'/>} />
            <Route
              exact
              path={urls.location}
              component={(props) => <SignUpLocation {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.requestAccess}
              component={(props) => <AccessRequest {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.loginRequest}
              component={(props) => <LoginRequest {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.magicLinkSent}
              component={(props) => <MagicLink {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.resetPassword}
              component={(props) => <MagicLink {...props} {...props.location.state} resetPassword={true} />}
            />
            <Route exact path={urls.home} component={(props) => <Home {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.thankyouForYourInterest}
              component={(props) => <ThankyouForYourInterest {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.grantPermissions}
              component={(props) => <GrantPermissions {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.invitations}
              component={(props) => <Invitations {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.browseAllType}
              component={(props) => (
                <Browse {...props} {...props.location.state} initTabIndex={0} {...props.match.params} />
              )}
            />
            <Route
              exact
              path={urls.browseDepartment}
              component={(props) => (
                <Browse {...props} {...props.location.state} initTabIndex={2} {...props.match.params} />
              )}
            />
            <Route
              exact
              path={urls.browseJob}
              component={(props) => (
                <Browse {...props} {...props.location.state} initTabIndex={1} {...props.match.params} />
              )}
            />
            <Route
              exact
              path={urls.browseCompnay}
              component={(props) => (
                <Browse
                  {...props}
                  {...props.location.state}
                  initTabIndex={0}
                  hideSidebar={true}
                  {...props.match.params}
                />
              )}
            />
            <Route exact path={urls.refer} component={(props) => <Refer {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.userForm}
              component={(props) => <EditProfile {...props} {...props.location.state} />}
            />
            <Route exact path={urls.web} component={(props) => <Web {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.initialScreen}
              component={(props) => <InitialScreen {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.getJobDetail}
              component={(props) => <OpeningDetail {...props} {...props.location.state} />}
            />
            <Route exact path={urls.browse} component={(props) => <Browse {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.browseAll}
              component={(props) => <Browse {...props} {...props.location.state} initTabIndex={0} />}
            />
            <Route
              exact
              path={urls.browseDepartments}
              component={(props) => <Browse {...props} {...props.location.state} initTabIndex={2} />}
            />
            <Route
              exact
              path={urls.browseJobs}
              component={(props) => <Browse {...props} {...props.location.state} initTabIndex={1} />}
            />
            <Route
              exact
              path={urls.browseEmployers}
              component={(props) => <Browse {...props} {...props.location.state} initTabIndex={1} />}
            />
            <Route
              exact
              path={urls.tracker}
              component={(props) => <Tracker {...props} {...props.location.state} initTabIndex={1} />}
            />
            <Route
              exact
              path={urls.sentJobs}
              component={(props) => <Tracker {...props} {...props.location.state} initTabIndex={0} />}
            />
            <Route
              exact
              path={urls.opportunityDetails}
              component={(props) => <OpportunityDetails {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.trackerSearch}
              component={(props) => <TrackerSearch {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.notifications}
              component={(props) => <Notifications {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.recommendations}
              component={(props) => <Recommendations {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.networkInvite}
              component={(props) => <YourNetworkInvite {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.networkConnect}
              component={(props) => <YourNetworkConnect {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.followCompanies}
              component={(props) => <FollowCompanies {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.resetForgetPassword}
              component={(props) => <ResetPassword {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.publicReferralForm}
              component={(props) => <PublicReferralForm {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.setPassword}
              component={(props) => <SetPassword {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.forgetPassword}
              component={(props) => <ForgetPassword {...props} {...props.location.state} />}
            />
            <Route exact path={urls.invite} component={(props) => <Invite {...props} {...props.location.state} />} />
            <Route
              exact
              path={urls.generalSignup}
              component={(props) => <Invite {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.referDetail}
              component={(props) => <JobPost {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.publicJobApplication}
              component={(props) => <PublicJobApplication {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.dashboardPublicJobApplication}
              component={(props) => <DashboardPublicJobApplication {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.linkedIn}
              component={(props) => <LinkedinCallback {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.publicReferral}
              component={(props) => <PublicReferral {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.referredJobPost}
              component={(props) => <ReferredJobPost {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.jobDecline}
              component={(props) => <PublicJobDecline {...props} {...props.location.state} />}
            />
            <Route
              exact
              path={urls.browseEmployer}
              component={(props) => (
                <EmployerProfileOverview {...props} {...props.location.state} {...props.match.params} />
              )}
            />
            <Route
              exact
              path={urls.referRecordCreated}
              component={(props) => {
                let urlParams = parse(props.location.search);
                Actions.signInEmail({
                  initialValues: {
                    refer_signin: urlParams.public_job_application,
                    email: urlParams.email,
                  },
                });
                return null;
              }}
            />
            <Route
              exact
              path={urls.googleContactSyncSuccess}
              component={(props) => {
                Actions.grantPermissions();
                return null;
              }}
            />
            <Route
              exact
              path={urls.confirmEmail}
              component={(props) => {
                const urlInfo = props.location.search.split('?');
                const urlParams = qs.parse(urlInfo[1]);
                Actions.signInPassword({
                  initialValues: {
                    magic_token: urlParams.magic_token,
                    email: urlParams.email,
                    is_confirmation: urlParams.is_confirmation,
                    confirm_email_param: true,
                    oldAccount: urlParams.oldAccount,
                    isJoinAndTrack: urlParams.is_join_and_track,
                    public_job_application: urlParams.public_job_application,
                  },
                  step: 'create_password',
                });
                return null;
              }}
            />
            <Route
              exact
              path={urls.confirmChangePassword}
              component={(props) => {
                let params = parse(props.location.search);
                Actions.resetForgetPassword({
                  initialValues: {
                    reset_password_token: params.reset_password_token,
                    email: params.email,
                    is_confirmation: params.is_confirmation,
                  },
                });
                return null;
              }}
            />
            {Platform.OS !== 'web' && (
              <Route
                exact
                path={urls.completeProfile}
                component={(props) => <CompleteProfile {...props} {...props.location.state} />}
              />
            )}
            <Redirect to="/" />
          </Switch>
        </PageTracker>
        {Platform.OS !== 'web' && <DeepLinking />}
      </Router>
    );
  }
}

export default Routes;
