import React, { useRef, useEffect, useState } from 'react';
import { reduxForm, Field, change, initialize } from 'redux-form';
import { View, Image, TouchableOpacity, Keyboard } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';

import Actions from '../../RouteActions';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import qs from 'qs';
import { parse } from 'search-params';
import * as AsyncStorage from '../../AsyncStorage';
import { apiSeverUrl } from '../../services/baseService';
//layout
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import LinkedInSsoLogin from '../LinkedinSsoLogin/LinkedinSsoLogin';

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput from '../../components/FormInput/FormInput';
import Text from '../../components/Text/Text';

//images
import appleIcon from '../../assets/svg/apple-icon.svg';
import googleIcon from '../../assets/svg/google-icon1.svg';
import { logout, setSpinnerHidden } from '../Settings/actions';
import microsoftIcon from '../../assets/svg/microsoft-icon.svg';
import unionIcon from '../../assets/svg/union-icon.svg';

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';

import * as actions from './actions';

//utils
import { notification, validateEmail } from '../../helperFunctions';
import * as redirectActions from '../../globalActions/redirectActions';
import GoogleSSOButton from '../GoogleSSOLogin/GoogleSSOButton';
import * as signupActions from "../SignUp/actions"
import SSOModal from '../../components/SSOModal/SSOModal';
import CustomToast from '../../components/Toast/CustomToast';
const SignInEmail = (props) => {
  const { errorMessage } = props;
  const [loader, setloader] = useState(true);
  const [SsoEmail , setSsoEmail] = useState(false);
  const { initialValues, formValues, location, handleSubmit, signInLoading, redirect, onLogout, setSpinnerHidden, verifySsoEnabled } =
    props;
  const [showSsoModal, setShowSsoModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setShowToast(true);
  
      setTimeout(() => {
        const url = new URL(window.location.href);
        if (url.searchParams.has("azure_error")) {
          url.searchParams.delete("azure_error");
          window.history.replaceState({}, document.title, url.toString());
        }
      }, 1000);
    }
  }, [errorMessage]);

  useEffect(() => {
    const urlParams = qs.parse(window.location.search.replace('?', ''));
    if (urlParams && urlParams.logout) {
      onLogout();
    }
  }, []);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleCloseLoader = () => {
    setTimeout(() => {
      return setloader(false);
    }, 1000);
  };

  useEffect(() => {
    const debouncedVerify = debounce(() => {
        verifySsoEnabled({ email: formValues.email }, () => setSsoEmail(true), () => setSsoEmail(false));
    }, 500); 

    debouncedVerify();

    return () => {
        debouncedVerify.cancel(); 
    };
}, [formValues.email]);

  useEffect(() => {
    const refer_signin = initialValues && initialValues.refer_signin;
    AsyncStorage.getAccessToken().then((token) => {
      if (token) {
        // openNotificationDrawer();
        if (!Boolean(refer_signin)) {
          redirect(location.search);
          handleCloseLoader();
        } else if (Boolean(refer_signin)) {
          Actions.tracker();
          handleCloseLoader();
        }
        return null;
      } else {
        setSpinnerHidden();
        setloader(false);
      }
    });
  }, []);

  const handleContinue = () => {
    if (!formValues.email) {
      notification.ref.show({
        message: 'Email is required',
        isError: true,
      });
    } else if (!validateEmail(formValues.email)) {
      notification.ref.show({
        message: 'Please enter valid email',
        isError: true,
      });
    }
  };

  const outlookSsoOauth = () => {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = `${apiSeverUrl}/auth/microsoft_graph_auth?sso_signup=${true}`;
    document.body.appendChild(form);
    form.submit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!formValues.email || !formValues.password) {
      notification.ref.show({
        message: 'Please fill all mandatory fields',
        isError: true,
      });
      return;
    }
    handleSubmit();
  };

  const disabled = signInLoading || !formValues.email || !validateEmail(formValues.email) || !formValues.password || SsoEmail;

  if (loader)
    return (
      <AuthLayout>
        <Spinner visible />
      </AuthLayout>
    );
  return (
    <AuthLayout>
      <CustomToast
          message={errorMessage}
          visible={showToast}
          onDismiss={() => setShowToast(false)}
        />
      <View>
        <View style={styles.formWrapper}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Login</Text>
          </View>
          <View>
            <View style={styles.formFieldWrapper}>
              <Field
                component={FormInput}
                name="email"
                disabled={Boolean(initialValues && initialValues.email)}
                placeholder="Email"
                wrapperStyle={[formLayout.fullWidth]}
                labelStyle={styles.labelStyle}
                inputStyle={styles.inputStyle}
                inputRef={(ref) => (emailRef.current = ref)}
                autoFocus={true}
                returnKeyType="next"
                keyboardType="email-address"
                onSubmitEditing={handleContinue}
                onBlur={() => {
                if (validateEmail(formValues.email)) verifySsoEnabled({ email: formValues.email }, ()=>setShowSsoModal(true), ()=>{});
                      }}
              />
            </View>
          </View>
          <View style={styles.formFieldWrapper}>
            <Field
              component={FormInput}
              name="password"
              placeholder="Password"
              secureTextEntry
              wrapperStyle={[formLayout.fullWidth]}
              labelStyle={styles.labelStyle}
              inputStyle={styles.inputStyle}
              visibilityIconButtonStyle={{
                right: 14,
                bottom: 20,
              }}
              inputRef={(ref) => (passwordRef.current = ref)}
              returnKeyType="send"
              onSubmitEditing={submitForm}
            />
            <TouchableOpacity style={{ width: 92, marginLeft: 'auto' }} onPress={() => Actions.forgetPassword()}>
              <Text
                style={{
                  color: '#0FBC71',
                  fontSize: 12,
                  marginTop: 6.5,
                }}
              >
                Forgot Password
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.formFieldWrapper}>
            <View style={{ height: 65, marginTop: 11 }}>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <View
                  style={{
                    backgroundColor: '#C4C4C4',
                    width: 10,
                    height: 1,
                    marginRight: 7,
                  }}
                />
                <Text style={{ color: '#000000', fontSize: 12 }}> OR </Text>
                <View
                  style={{
                    backgroundColor: '#C4C4C4',
                    width: 10,
                    height: 1,
                    marginLeft: 7,
                  }}
                />
              </View>

              <View
                style={{
                  marginTop: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  margin: 'auto',
                  flexDirection: 'row',
                  width: 270,
                }}
                className="SSO-Questions-wrapper"
              >
                <TouchableOpacity style={styles.iconButton} onClick={() => outlookSsoOauth()}>
                  <Image source={microsoftIcon} resizeMode="contain" style={{ width: 19, height: 15 }} />
                </TouchableOpacity>
                <GoogleSSOButton />
                <LinkedInSsoLogin />
                <TouchableOpacity style={styles.iconButton} onPress={() =>Actions.signInWithSSO()}>
                  <Image source={unionIcon} resizeMode="contain" style={{ width: 19, height: 15 }} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.formFieldWrapper}>
            <PrimaryButton
              onPress={submitForm}
              disabled={disabled}
              style={disabled ? styles.disabledButton : styles.button}
              textStyle={disabled ? styles.disabledButtonText : styles.buttonText}
            >
              LOGIN
            </PrimaryButton>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Text style={{ color: '#FFFFFF', fontSize: 12 }}>Need an account?&nbsp;</Text>
          <TouchableOpacity onPress={() => Actions.signUp()}>
            <Text
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                textDecorationLine: 'underline',
              }}
            >
              <b>SignUp</b>
            </Text>
          </TouchableOpacity>
        </View>
        <SSOModal showSsoModal={showSsoModal} setShowSsoModal={setShowSsoModal}/>
      </View>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    signInLoading: state.authReducer.signInLoading,
    formValues: (state.form.signInEmail && state.form.signInEmail.values) || {},
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (values) => {
      Keyboard.dismiss();
      if (!values.password && !values.magic_token) {
        notification.ref.show({
          message: 'Password is required',
          isError: true,
        });
      } else {
        const params = parse(props.location.search);
        const { notify } = params;
        values.notify = notify;
        values.oldAccount = 1;
        values.refer_signin = props.publicReferSignup;
        dispatch(actions.signIn(values, props.location.search));
      }
    },
    redirect: (params) => {
      dispatch(redirectActions.redirect(params));
    },
    openNotificationDrawer: () => dispatch(openNotificationsDrawer()),
    sendResetPasswordEmail: (params) => {
      dispatch(actions.resetPassword(params));
    },
    onLogout: () => {
      dispatch(logout());
    },
    setSpinnerHidden: () => {
      dispatch(setSpinnerHidden());
    },
    verifySsoEnabled: (params, setShowSsoModal , EnableLogin) => {
      dispatch(signupActions.verifySsoEnabled(params, setShowSsoModal , EnableLogin));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'signInEmail' })(SignInEmail));
