import { StyleSheet } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  formWrapper: {
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    width: 375,
    margin: "auto",
    paddingTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 38,
  },
  dropdownButton: {
    minWidth: 320,
    padding: 10,
    fontSize: 16,
    height: 50,
    color: "gray",
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  dropdownMenu: {
    position: "absolute",
    top: 50,
    left: 0,
    right: 0,
    backgroundColor: "white",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    maxHeight: 200,
    zIndex: 10,
  },
  dropdownItem: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },

  organizationListContainer: {
    width: "90%",
    maxHeight: 200,
    backgroundColor: "#fff",
    borderRadius: 8,
    paddingVertical: 5,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  errorText: {
    color: "red"
  },

  noOrgFoundText: {
    textAlign: "center",
    color: "#888",
    fontSize: 14,
    paddingVertical: 10,
  },  

  organizationItem: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottomWidth: 0,
  },
  
  orgLogo: {
    width: 30,
    height: 30,
    marginRight: 10,
    borderRadius: 15,
    resizeMode: "contain",
    backgroundColor: "transparent",
  },
  
  searchInput: {
    width: "90%",
    height: 50,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 10,
    marginBottom: 10,
    fontSize: 14,
    backgroundColor: "#fff",
  },  
  
  orgName: {
    fontSize: 16,
    color: "#000",
  },
  selectedOrganization: {
    backgroundColor: "#e6f7ff",
  },
  header: {
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 256,
    margin: "auto",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  iconImg: {
    width: 19,
    height: 10,
  },
  headerText: {
    color: "#0FBC71",
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.8px",
    marginTop: 20,
  },
  subHeaderText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 16,
    lineHeight: "24px",
    marginTop: 20,
    textAlign: "center",
  },
  formFieldWrapper: {
    marginTop: 10,
    position: 'relative'
  },
  labelStyle: {
    marginTop: 10,
    backgroundColor: "#FFFFFF",
  },
  inputStyle: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 12,
  },
  button: {
    borderWidth: 0,
    backgroundColor: $green,
  },
  disabledButton: {
    borderWidth: 0,
    backgroundColor: "#F2F2F2",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 12,
  },
  disabledButtonText: {
    color: "#808080",
    fontSize: 12,
  },
});
