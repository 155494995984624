import React, { Component } from 'react';
import axios from 'axios';
import {
    View,
    Image,
    TouchableHighlight,
    TouchableOpacity
} from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import FormInputPhone from '../../components/FormInputPhone/FormInputPhone';
import FormInputNew from '../../components/FormInputNew/FormInputNew';
import helpIcon from '../../assets/img/help_icon.svg';
import closeIcon from '../../assets/img/close_icon.svg';
//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SignUpButton from '../../components/SignupButton/SignupButton';
import Text from '../../components/Text/Text';
import { RESET_CONFIRM_NUMBER } from '../../constants/actions/auth';
import { formLayout } from '../../generalStyles';
import * as redirectActions from '../../globalActions/redirectActions';
//utils
import { notification } from '../../helperFunctions';
//actions
import * as phoneNumberActions from '../PhoneNumber/actions';
import styles from '../SignUp/old-styles';
import SignUpBase from '../SignUpBase/SignUpBase';
import * as actions from './actions';
import Actions from '../../RouteActions';
import * as authActionsConstants from '../../constants/actions/auth';
//styles
import ownStyles from './styles';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { urls } from '../../urls';
import history from '../../RouteHistory'
import ReCAPTCHA from 'react-google-recaptcha';

class SignUpPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectingPhone: true,
            showTooltip: false,
            isLoading: false,
            countryCode: 'us',
            isDisabled: true,
            recaptchaToken: null,
        };
    }

    componentDidMount() {
        const { userCurrentStep, redirect, sendToLoginScreenIfUserBlank, formValues} = this.props;
        const url = 'http://ip-api.com/json';

        if (!userCurrentStep && !formValues.email){
            sendToLoginScreenIfUserBlank();
            history.push(urls.signIn)
        }

        if (userCurrentStep != 'phone_number') {
            redirect();
        }

        const fetchData = async () => {
            this.setState({ isLoading: true, isDisabled: false });
            axios
                .get(url)
                .then((response) => {
                    this.setState({
                      countryCode: response.data.countryCode.toLowerCase(),
                    });
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        };

        fetchData();
    }

    clearLocalNavState = () => {
        this.setState({
            selectingPhone: false,
            selectingCompany: false
        });
    };

    onStartPhoneSelect = () => {
        this.props.resetPhoneField();
        this.setState({ selectingPhone: true });
    };

    displayTooltip = () => {
        this.setState({ showTooltip: true });
    };

    hideTooltip = () => {
        this.setState({ showTooltip: false });
    };

    onCaptchaChange = (token) => {
        this.setState({
            recaptchaToken: token,
            isDisabled: false
        });
    };

    setButtonActive = () => this.setState({ isDisabled: false });

    submitForm = (e) => {
        e.preventDefault();
        const { formValues, currentUser } = this.props;
        formValues.email = currentUser.email;
        formValues.recaptchaToken = this.state.recaptchaToken;

        if (formValues.phone_number && formValues.recaptchaToken) {
            this.setState({ isDisabled: true });
            this.props.onSubmitPhone(formValues, this.setButtonActive);
        } else {
            notification.ref.show({
                message: 'Please enter phone number and verify captcha',
                isError: true
            });
        }
    };

    renderSignUpPhoneDescription = () => {
        const { onGoBackSignUp } = this.props;
        return (
            <AuthLayout>
                <View>
                    <View style={styles.formWrapperToolTip}>
                        <TouchableHighlight
                            style={styles.unsetPosition}
                            onClick={this.hideTooltip}
                        >
                            <Image source={closeIcon} style={styles.tooltip} />
                        </TouchableHighlight>
                        <View style={styles.descriptionWrapper}>
                            <Text style={styles.descriptionText}>
                                Boon will only use your number for security
                                purposes.
                                <br />
                                Standard text messaging rates may apply.
                            </Text>
                        </View>
                    </View>
                    <View style={ownStyles.formBottomWrapper}>
                        <Text style={ownStyles.formBottomTextWhite}>
                            Accidentally enter the wrong personal details?
                            {'\n'}
                            <TouchableOpacity onPress={onGoBackSignUp}>
                                <Text style={ownStyles.formBottomTextUnderline}>
                                    <b>Go back</b>
                                </Text>
                            </TouchableOpacity>
                        </Text>
                    </View>
                </View>
            </AuthLayout>
        );
    };

    renderSignUpPhoneForm = () => {
        const { onSetupLater, onGoBackSignUp } = this.props;
        const { countryCode, isLoading } = this.state;
        return (
            <AuthLayout>
                {isLoading ? (
                    <Spinner visible={isLoading} />
                ) : (
                    <View>
                        <View style={styles.formWrapper}>
                            <TouchableHighlight
                                style={styles.unsetPosition}
                                onClick={this.displayTooltip}
                            >
                                <Image
                                    source={helpIcon}
                                    style={styles.tooltip}
                                />
                            </TouchableHighlight>
                            <View style={styles.header}>
                                <Text style={styles.headerText}>
                                    What's your phone number?
                                </Text>
                            </View>

                            <View style={ownStyles.phoneInputWrapper}>
                                <Field
                                    component={FormInputPhone}
                                    name="phone_number"
                                    label="phone number"
                                    keyboardType="phone-pad"
                                    autoFocus
                                    variant="outlined"
                                    placeholder="Phone number"
                                    showPlaceholder={true}
                                    initialCountry={countryCode}
                                />
                            </View>
                            <View style={{ marginVertical: 10 }}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={this.onCaptchaChange}
                                />
                            </View>
                            <View style={ownStyles.addPaddingVertical}>
                                <SignUpButton
                                    onClick={this.submitForm}
                                    disabled={this.state.isDisabled || !this.state.recaptchaToken}
                                >
                                    Next
                                </SignUpButton>
                            </View>
                            <View style={styles.centerAlign}>
                                <TouchableOpacity onClick={onSetupLater}>
                                    <Text style={ownStyles.setupLaterButton}>
                                        Setup later
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={ownStyles.formBottomWrapper}>
                            <Text style={ownStyles.formBottomTextWhite}>
                                Accidentally enter the wrong personal details?
                                {'\n'}
                                <TouchableOpacity onPress={onGoBackSignUp}>
                                    <Text
                                        style={
                                            ownStyles.formBottomTextUnderline
                                        }
                                    >
                                        <b>Go back</b>
                                    </Text>
                                </TouchableOpacity>
                            </Text>
                        </View>
                    </View>
                )}
            </AuthLayout>
        );
    };

    render() {
        const { showTooltip } = this.state;
        return showTooltip
            ? this.renderSignUpPhoneDescription()
            : this.renderSignUpPhoneForm();
    }
}

SignUpPhone = reduxForm({ form: 'signUpPhoneForm' })(
    withGetScreen(SignUpPhone)
);

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.signUpPhoneForm && state.form.signUpPhoneForm.values
                ? state.form.signUpPhoneForm.values
                : {},
        userCurrentStep: state.stepsReducer.step,
        currentUser: state.authReducer.currentUser,
        phoneConfirmationSent: state.authReducer.phoneConfirmationSent,
        phoneConfirmationData: state.authReducer.phoneConfirmationData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPhoneField: () => {
            dispatch({ type: RESET_CONFIRM_NUMBER });
        },
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
        onSubmitPhone: (formValues, setButtonActive) => {
            dispatch(
                actions.getNumberConfirmation(formValues, setButtonActive)
            );
        },
        onSetupLater: () => {
            dispatch(actions.sendSkipMobileVerification());
        },
        onGoBackSignUp: () => {
            dispatch(
                actions.updateStepsOnBackButton({
                    current: 'phone_number',
                    new: 'signup'
                })
            );
        },
        submitConfirmationCode: (confirmationCode) => {
            dispatch(
                change('signUpPhoneForm', 'confirmation_code', confirmationCode)
            );
        },
        resendCode: (formValues) => {
            dispatch(
                phoneNumberActions.getNumberConfirmation(formValues, true)
            );
        },
        sendToLoginScreenIfUserBlank: () => {
            dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPhone);
