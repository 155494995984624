import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, Platform } from 'react-native';
import { reduxForm, change } from 'redux-form';
import { isEmpty, extend as l_extend } from 'lodash-es';
import CloseIcon from '@material-ui/icons/Close';
import './Refer.css';
//components
import 'react-phone-input-2/lib/material.css';
import Text from '../../components/Text/Text';

//styles
import styles from './styles';
import { formLayout, drawerElement } from '../../generalStyles';

//actions
import * as actions from './actions';
import { closeDrawer } from '../../DrawerActions.js';
import Actions from '../../RouteActions';

//utils
import { notification } from '../../helperFunctions';
import * as orgProgfile from '../OrganizationProfile/actions';
//constants
import StrengthenReferFormWeb from './StrengthenReferForm/StrengthenReferForm.web';
import AlreadyReferUserDetails from './StrengthenReferForm/AlreadyReferUserDetails';
import { loadDefaultSkillOptions } from './StrengthenReferForm/defualtSkill';
import ReferUserForm from './ReferUserForm';
import ReferDropDownSelections from './ReferDropDownSelections';
import ReferStrengthDetails from './ReferStrengthDetails';
import ReferUserAgain from './ReferUserAgain';
import StrengthBar from './StrengthBar';

const CANDIDATE_MESSAGE =
  'Hey!\n\nThere is an opening here at {{COMPANY_NAME}} that I thought you could be a good fit for. Take a look and apply if you’re interested to learn more. Here’s hoping!';
const EMPLOYER_MESSAGE =
  'I think {{CANDIDATE_NAME}} could be a good fit for this opening. Let me know if you have any questions.';

const Refer = ({
  setFieldValue,
  currentUser,
  getOrganizationSettings,
  defaultOrganizationSettings,
  getOrganization,
  currentSelectedOrg,
  formValues,
  resetForm,
  onDrawerClose,
  refer,
  updateReferralRequest,
  organizationDetails,
  generalReferrals,
}) => {
  const initialStrengthQuestions = {
    skills: [],
    candidateRating: '',
    workWithClient: '',
    openToNewOppertunities: '',
    isDiversityHire: '',
    knowThePerson: '',
    isGoodFit: '',
    filesUploaded: [],
    filesUploadedLinks: [],
    userLinksArray: [{ type: 'pw', value: '' }],
    licenseAvailable: '',
  };
  const [selectingOrganization, setselectingOrganization] = useState(false);
  const [showSelection, setshowSelection] = useState({ type: '', title: '' });
  const [updateStrengthData, setupdateStrengthData] = useState(false);
  const [updateReferalId, setupdateReferalId] = useState(null);
  const [allSkills, setallSkills] = useState([]);
  const [strengthQuestions, setstrengthQuestions] = useState(initialStrengthQuestions);
  const [progressValues, setprogressValues] = useState(0);
  const [autoSubmitForm, setAutoSubmitForm] = useState(false);
  const [disableSkipButton, setDisableSkipButton] = useState(false);
  const [refErrorType, setRefErrorType] = useState('');

  const [strengthQuestionsSettings, setstrengthQuestionsSettings] = useState({
    attach_file: true,
    attach_url: true,
    how_good_are_they: true,
    know_the_person_level: true,
    worked_with_person: true,
    open_for_new_opportunities_status: true,
    diversity_hire: true,
    license_available: '',
  });
  const [referStep, setreferStep] = useState('fill_refer_detail');

  useEffect(() => {
    setFieldValue('candidate_message', CANDIDATE_MESSAGE);
    setFieldValue('employer_message', EMPLOYER_MESSAGE);
    setFieldValue('referrer_email', `${currentUser.email ? currentUser.email : ''}`);
    setFieldValue('referrer_name', `${currentUser.full_name ? currentUser.full_name : ''}`);
    if (currentSelectedOrg && currentSelectedOrg.id) {
      getOrganizationSettings(currentSelectedOrg.id);
      loadDefaultSkillOptions(currentSelectedOrg.id).then((response) => setallSkills(response));
    }
    if (currentSelectedOrg && currentSelectedOrg.label) {
      setFieldValue('candidate_message', CANDIDATE_MESSAGE.replace('{{COMPANY_NAME}}', currentSelectedOrg.label));
    }
    if (currentSelectedOrg.label !== 'Boon') {
      getOrganization(currentSelectedOrg.id);
    }
    return () => {
      if (referStep === 'fill_strength_questions') return skipStrengthForm();
    };
  }, []);

  const handleToggleDropDownsOptions = (useCase, heading) => setshowSelection({ type: useCase, title: heading });

  const getFullName = (userObj) => {
    let first_name = userObj.first_name || formValues.first_name || '';
    let last_name = userObj.last_name || formValues.last_name || '';
    return first_name.concat(' ', last_name);
  };

  const getCandidateName = () => {
    let first_name = formValues.first_name || '';
    let last_name = formValues.last_name || '';
    return first_name.trim().concat(' ', last_name.trim());
  };

  const getPhoneNumber = (userObj) => {
    let phone = '';
    if (userObj.phone_numbers) {
      phone = userObj.phone_numbers[userObj.phone_numbers.length - 1];
    } else if (userObj.phone_number) {
      phone = userObj.phone_number;
    }
    return phone;
  };

  useEffect(() => {
    setFieldValue('employer_message', EMPLOYER_MESSAGE.replace('{{CANDIDATE_NAME}}', `${getCandidateName()}`));
  }, [formValues.first_name, formValues.last_name]);

  const onEndUserSelect = (userObj) => {
    setFieldValue('email', userObj.email);
    setFieldValue('name', `${userObj.full_name || getFullName(userObj)}`);
    setFieldValue('first_name', userObj.first_name || '');
    setFieldValue('last_name', userObj.last_name || '');
    setFieldValue('phone_number', getPhoneNumber(userObj));
    setFieldValue('user_id', userObj.full_name || userObj.first_name);
    setFieldValue('user_contact_id', userObj.id ? userObj.id : null);
    setFieldValue('employer_message', EMPLOYER_MESSAGE.replace('{{CANDIDATE_NAME}}', `${getFullName(userObj)}`));
    setFieldValue('linkedin_link', userObj.linkedin_url || '');
    setshowSelection({ type: '', title: '' });
    handleToggleDropDownsOptions('', '');
  };

  const onSelectCompany = (company) => {
    setFieldValue('organization_id', company);
    setFieldValue('job_id', '');
    setFieldValue('candidate_message', CANDIDATE_MESSAGE.replace('{{COMPANY_NAME}}', company.name));
    setselectingOrganization(false);
    handleToggleDropDownsOptions('', '');
  };

  const stengthSettingsLength = () => Object.values({ ...strengthQuestionsSettings }).filter(Boolean).length;

  const onSelectOpening = (job) => {
    setFieldValue('job_id', job);
    handleToggleDropDownsOptions('', '');
  };

  const referCandiateAgain = () => {
    const { referrer_name, referrer_email } = formValues;
    resetForm();
    setreferStep('fill_refer_detail');
    setstrengthQuestions(initialStrengthQuestions);
    setFieldValue('referrer_name', referrer_name);
    setFieldValue('referrer_email', referrer_email);
    setFieldValue('candidate_message', CANDIDATE_MESSAGE.replace('{{COMPANY_NAME}}', currentSelectedOrg.name));
    setFieldValue('employer_message', EMPLOYER_MESSAGE);
  };
  const onOpenOpeningSelect = () => {
    if (formValues.organization_id) {
      handleToggleDropDownsOptions('opening', 'Select Opening');
    } else {
      notification.ref.show({
        message: 'Please select organization first',
        isError: true,
      });
    }
  };

  const clearState = () => {
    setselectingOrganization(false);
    handleToggleDropDownsOptions('', '');
  };

  const closeReferDrawer = () => {
    resetForm();
    onDrawerClose();
    if (referStep === 'fill_strength_questions') return skipStrengthForm();
  };

  const handleStrengthChange = (e, key) => {
    e.persist();
    setstrengthQuestions({
      ...strengthQuestions,
      [key]: key === 'isDiversityHire' ? (e.target.checked ? 'true' : '') : e.target.value,
    });
  };

  const handleSetSkills = (e) => {
    setstrengthQuestions({ ...strengthQuestions, skills: e });
  };

  const handleUploadUserFiles = (files, key) => {
    setstrengthQuestions({ ...strengthQuestions, [key]: files });
  };

  const handleUploadUserLinks = (links) => {
    setstrengthQuestions({ ...strengthQuestions, userLinksArray: links });
  };

  const getStrengthFormData = () => {
    const {
      skills,
      candidateRating,
      workWithClient,
      openToNewOppertunities,
      isDiversityHire,
      knowThePerson,
      isGoodFit,
      filesUploaded,
      userLinksArray,
      filesUploadedLinks,
      licenseAvailable,
    } = strengthQuestions;
    const body = {
      skills_params: skills.map((skill) => skill.id).join(','),
      diversity_hire: isDiversityHire,
      know_the_person_level: candidateRating,
      links: userLinksArray.filter((e) => e.value !== ''),
      isFileUploaded: true,
      open_for_opportunities_status: openToNewOppertunities,
      worked_with_person: '',
      pdf_files: filesUploaded,
      current_file_ids: filesUploadedLinks.map((file) => file.id).join(','),
      license_available: '',
      know_the_person_level_radio: knowThePerson,
      why_are_they_good_fit: isGoodFit,
    };
    if (workWithClient) {
      body.worked_with_person = workWithClient === 'Yes';
    }

    if(licenseAvailable) {
      body.license_available = licenseAvailable === 'Yes';
    }

    setstrengthQuestions({
      ...strengthQuestions,
      userLinksArray: userLinksArray.filter((e) => e.value !== ''),
    });
    return body;
  };
  const submitReferForm = () => {
    return updateReferralRequest(
      {
        ...formValues,
        ...getStrengthFormData(),
        attention_required: true,
        create_refer_request: false,
        general_referral: generalReferrals,
      },
      updateReferalId,
      handleShowHideStrengthForm
    );
  };

  const licenseAvailable = (value) => {
    if (value === null || isEmpty(value)) return null;
    return value ? 'Yes' : 'No';
  };

  const checkCandiadateAlreadyRefered = () => {
    if (!formValues.job_id && generalReferrals) {
      setFieldValue('job_id', {
        title: 'General Referral',
        category: 'general',
      });
    }
    refer(
      {
        ...formValues,
        general_referral: generalReferrals,
      },
      handleShowHideStrengthForm,
      organizationDetails.show_referral_enrichment_questions
    );
    setupdateStrengthData(true);
  };

  const skipStrengthForm = () => {
    if (!updateStrengthData) {
      return refer(
        {
          ...formValues,
          create_refer_request: false,
          isSkipped: true,
          general_referral: generalReferrals,
        },
        handleShowHideStrengthForm,
        organizationDetails.show_referral_enrichment_questions
      );
    }
    return setreferStep('skip_Strength_Questions');
  };

  const workedWithPerson = (val) => {
    if (val === null) return null;
    if (val) return 'Yes';
    if (!val) return 'No';
  };
  const handleShowHideStrengthForm = (
    response_code,
    isReferalCreated,
    isSkipped,
    referId,
    strengthQuestionsData,
    strengthQuestionSettings
  ) => {
    if (!!strengthQuestionSettings) {
      setstrengthQuestionsSettings(strengthQuestionSettings);
    }
    if (!!strengthQuestionsData) {
      const {
        skill_ids,
        know_the_person_level,
        worked_with_person,
        open_for_opportunities_status,
        diversity_hire,
        know_the_person_level_radio,
        why_are_they_good_fit,
        pdf_files,
        links,
        license_available,
      } = strengthQuestionsData;
      setstrengthQuestions({
        ...strengthQuestions,
        skills: skill_ids.length > 0 ? allSkills.filter((skill) => skill_ids.includes(skill.id)) : [],
        candidateRating: know_the_person_level,
        workWithClient: workedWithPerson(worked_with_person),
        openToNewOppertunities: open_for_opportunities_status,
        userLinksArray: links.length > 0 ? links : [{ type: 'pw', value: '' }],
        isDiversityHire: diversity_hire,
        knowThePerson: know_the_person_level_radio,
        isGoodFit: why_are_they_good_fit,
        filesUploaded: [],
        filesUploadedLinks: pdf_files.length > 0 ? pdf_files : [],
        licenseAvailable: licenseAvailable(license_available),
      });
    }
    if (referId) {
      setupdateReferalId(referId);
    }
    if (response_code === 0) {
      if (autoSubmitForm) {
        return setreferStep('fill_strength_questions');
      }
      if (
        !!organizationDetails.show_referral_enrichment_questions &&
        !organizationDetails.show_referral_enrichment_questions
      ) {
        return setreferStep('skip_Strength_Questions');
      }
      if (isReferalCreated) {
        if (!!isSkipped) {
          return setreferStep('skip_Strength_Questions');
        }
        setupdateStrengthData(false);
        return setreferStep('Strength_Questions_submittion_details');
      }
      setTimeout(() => {
        setAutoSubmitForm(true);
      }, 115000);
      setreferStep('fill_strength_questions');
    }
    if (response_code === 13 || response_code === 12 || response_code === 14) {
      setRefErrorType(response_code === 14 ? 'referral_time_limit' : 'referrral_unused');
      setreferStep('already_refered_user_details');
    }
    if (response_code === 416) {
      notification.ref.show({
        message: 'Invalid phone number',
        isError: true,
      });
    }
  };
  const handleCompleteReferStrenthForm = () => {
    setreferStep('fill_strength_questions');
    setupdateStrengthData(true);
    setDisableSkipButton(true);
  };

  const web = Platform.OS === 'web';

  return (
    <View
      style={{
        flex: 1,
        height: 'calc(100vh - 20px)',
        position: 'absolute',
        width: '100%',
      }}
    >
      <View
        style={[{ flex: 1, height: '100%' }, !web && { marginTop: 30 }]}
        keyboardShouldPersistTaps="always"
        enableAutomaticScroll
        extraScrollHeight={20}
        innerRef={(ref) => {
          this.scroll = ref;
        }}
      >
        <View style={styles.header}>
          <View style={[drawerElement.drawerHeader, formLayout.row, { backgroundColor: 'transparent' }]}>
            <Text style={drawerElement.titleText}>Send a Referral</Text>
            <Text style={[drawerElement.headerCloseButtonText, { color: '#808080' }]} onPress={closeReferDrawer}>
              <CloseIcon />
            </Text>
          </View>
        </View>
        {referStep === 'fill_refer_detail' && showSelection.title === '' && (
          <ReferUserForm
            formValues={formValues}
            currentSelectedOrg={currentSelectedOrg}
            defaultOrganizationSettings={defaultOrganizationSettings}
            setFieldValue={setFieldValue}
            onOpeningSelectDropDown={handleToggleDropDownsOptions}
            currentSelectedOrgLabel={currentSelectedOrg.label}
            checkCandiadateAlreadyRefered={checkCandiadateAlreadyRefered}
            onOpenOpeningSelect={onOpenOpeningSelect}
            generalReferrals={generalReferrals}
            showSelection={showSelection}
          />
        )}

        {referStep === 'already_refered_user_details' && (
          <AlreadyReferUserDetails
            closeSideBar={closeReferDrawer}
            candidate_name={formValues.name || formValues.first_name.concat(' ', formValues.last_name)}
            candidate_email={formValues.email}
            resetForm={referCandiateAgain}
            org_name={currentSelectedOrg.label}
            genRefFrequency={organizationDetails.general_ref_frequency}
            refErrorType={refErrorType}
          />
        )}

        {referStep === 'fill_strength_questions' && (
          <StrengthenReferFormWeb
            disableSkipButton={disableSkipButton}
            setAutoSubmitForm={setAutoSubmitForm}
            autoSubmitForm={autoSubmitForm}
            formValues={formValues}
            resetForm={referCandiateAgain}
            strengthQuestions={strengthQuestions}
            handleStrengthChange={handleStrengthChange}
            handleSetSkills={handleSetSkills}
            submitReferForm={submitReferForm}
            skipStrengthForm={skipStrengthForm}
            orgId={currentSelectedOrg.id}
            onDrawerClose={closeReferDrawer}
            showReferralStrengthenQuestions={organizationDetails.show_referral_enrichment_questions}
            handleUploadUserFiles={handleUploadUserFiles}
            handleUploadUserLinks={handleUploadUserLinks}
            handleCompleteReferStrenthForm={handleCompleteReferStrenthForm}
            diversityQuestion={strengthQuestions.isDiversityHire}
            updatedData={updateStrengthData}
            strengthQuestionsSettings={strengthQuestionsSettings}
            stengthSettingsLength={stengthSettingsLength}
            referStep={referStep}
            setprogressValues={setprogressValues}
            progressValues={progressValues}
            updateStrengthData={updateStrengthData}
            setreferStep={setreferStep}
          />
        )}

        {referStep === 'Strength_Questions_submittion_details' && (
          <>
            {organizationDetails.show_referral_enrichment_questions && (
              <StrengthBar showtitle={false} progressValues={progressValues} />
            )}
            <ReferStrengthDetails
              strengthQuestions={strengthQuestions}
              strengthQuestionsSettings={strengthQuestionsSettings}
            />
            <ReferUserAgain
              progressValues={progressValues}
              hideCompleteReferStrengthQuestion={organizationDetails.show_referral_enrichment_questions}
              handleCompleteReferStrenthForm={handleCompleteReferStrenthForm}
              resetForm={referCandiateAgain}
              onDrawerClose={closeReferDrawer}
            />
          </>
        )}

        {referStep === 'skip_Strength_Questions' && (
          <ReferUserAgain
            hideCompleteReferStrengthQuestion={false}
            progressValues={progressValues}
            handleCompleteReferStrenthForm={handleCompleteReferStrenthForm}
            resetForm={referCandiateAgain}
            onDrawerClose={closeReferDrawer}
          />
        )}
      </View>
      {showSelection.title !== '' && (
        <ReferDropDownSelections
          showSelection={showSelection}
          orgId={formValues.organization_id}
          onSelectOpening={onSelectOpening}
          onSelectCompany={onSelectCompany}
          onEndUserSelect={onEndUserSelect}
          clearState={clearState}
          selectingOrganization={selectingOrganization}
        />
      )}
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    formValues: state.form.referForm && state.form.referForm.values ? state.form.referForm.values : {},
    referFormSubmitting: state.refersReducer.referFormSubmitting,
    userCountryISOCode: state.authReducer.currentUser.country_iso_code,
    currentUser: state.authReducer.currentUser,
    closeContinueRefer: state.refersReducer.referFormContinue,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
    defaultOrganizationSettings: state.settingsReducer.defaultValues,
    organizationDetails: state.organizationsReducer.organizationDetails,
    generalReferrals: state.organizationsReducer.generalReferrals,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFieldValue: (field, value) => {
      dispatch(change('referForm', field, value));
    },
    refer: (data, handleShowHideStrengthForm, showStrengthen = true) => {
      let formData = l_extend({}, data);

      formData.organization_id = data.organization_id.id;

      if (formData.job_id) {
        formData.job_id = data.job_id.id;
      }

      formData.user_id = null;
      formData.email = formData.email.toLowerCase();
      formData.first_name = formData.first_name.trim();
      formData.last_name = formData.last_name.trim();
      formData.name = formData.first_name.trim().concat(' ', formData.last_name.trim());
      formData.linkedin_link = formData.linkedin_link || '';

      if (formData.job_id && formData.job_id === -1 && formData.create_refer_request) {
        delete formData.job_id;
      } else if (formData.create_refer_request || formData.job_id) {
        delete formData.general_referral;
      }

      if (!showStrengthen) {
        formData.create_refer_request = true;
      }

      formData.origin_channel = 'user_web_app';

      dispatch(actions.refer(formData, handleShowHideStrengthForm));
    },
    updateReferralRequest: (body, id, func) => {
      dispatch(actions.updateReferral(body, id, func));
      dispatch(actions.updateAttentionStatus({ attention_required: true }, id));
    },
    resetForm: () => {
      dispatch(actions.resetForm());
    },
    onDrawerClose: () => {
      dispatch(closeDrawer());
    },
    referClose: (userEmail) => {
      dispatch(closeDrawer());
      if (userEmail) {
        Actions.sentJobs({ myDirection: 'their' });
      }
    },
    referContinue: () => {
      dispatch(actions.referContinue());
    },

    getOrganizationSettings: (orgID) => {
      dispatch(actions.getDefaultSettings(orgID));
    },
    getOrganization: (orgID) => {
      dispatch(orgProgfile.getOrganizationDetails(orgID));
    },
  };
};

const refer = reduxForm({ form: 'referForm', enableReinitialize: true })(Refer);

export default connect(mapStateToProps, mapDispatchToProps)(refer);
