import Actions from '../../RouteActions';

import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';
import { getDeviceFingerprint, getPushToken, setAccessToken } from '../../AsyncStorage';

export function getCurrentUser() {
    return dispatch => {
        dispatch({type: authActionsConstants.SIGN_IN});
        return authService.getCurrentUser().then(response => {
            getDeviceFingerprint().then(deviceFingerprint => {
                getPushToken().then(pushToken => {
                    authService.updatePushToken({
                        device_fingerprint: deviceFingerprint,
                        push_token: pushToken
                    });
                });
            });

            dispatch({ type: authActionsConstants.SIGN_IN_SUCCESS, payload: response.data.payload });
            if (response.data.payload.access_token){
                setAccessToken(response.data.payload.access_token);
            }
            if (response.data.payload.organizations.length > 0){
                let currentSelectedOrg = { label: response.data.payload.organizations[0].organization.name, value: response.data.payload.organizations[0].organization.name, id: response.data.payload.organizations[0].organization.id, logo: response.data.payload.organizations[0].organization.logo_image_thumb, isUsHeadquarter: response.data.payload.organizations[0].organization.based_in_us_headquarter}
                dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
            }
            if (!response.data.payload.user.permissions_passed) {
                Actions.grantPermissions();
            }
            return response;
        }).catch((e) => {
            dispatch({type: authActionsConstants.SIGN_IN_ERROR});
            throw e;
        });
    };
}
